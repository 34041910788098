export enum ColumnType {
  text = 0,
  number = 1,
  date = 2,
  time = 3,
  datetime = 4,
  boolean = 5,
  list = 6,
  tag = 7,
  action = 8,
  checkbox = 9
}
